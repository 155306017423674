.offcanvas-end.language-slide {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.98);
}
@media only screen and (min-width: 993px) {
  .offcanvas-end.language-slide {
    width: 30%;
  }
}

.language-options {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  overflow: auto;
}

.language-options .btn {
  width: 95%;
}
