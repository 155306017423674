.cookie-consent {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    19,
    18,
    44,
    0.9
  ); /* Dark background with transparency */
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw; /* Ensures it spans the full width of the viewport */
  box-sizing: border-box; /* Includes padding in width calculation */
  z-index: 1000; /* Ensures it stays on top */
}

.cookie-consent button {
  margin-inline-end: 10px; /* Adds space to the right of each button */
}

.cookie-consent button:last-child {
  margin-inline-end: 0; /* Removes margin from the last button */
}
