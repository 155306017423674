.explore-modal {
  font-family: "Mandala", san-serif;
  color: #13122c;
}

.explore-modal .modal-content {
  background-color: rgba(238, 238, 238, 0.8);
}

.explore-modal-title1 {
  font-size: 1.2em;
  margin-inline-end: 10px;
}

.btn.exploreButton {
  font-weight: 300;
  border-radius: 22px;
  font-size: 0.9em;
  text-transform: none;
}

.explore-modal-header .btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
}
