.contact-slide {
  min-height: 100%;
}
@media only screen and (min-width: 993px) {
  .contact-slide {
    min-height: 400px;
  }
}
.contact-slide .offcanvas-header {
  background-color: var(--primary-color); /* Using the primary color variable */
  color: white; /* Setting the text color to white for better visibility */
}

.contact-slide .offcanvas-title h3 {
  margin-bottom: 0;
}

.contact-slide .offcanvas-body {
  padding: 20px; /* Providing some padding for content spacing */
  font-size: 1rem; /* Standard font size, adjust as needed */
  background-color: #f8f9fa; /* A light background for the body */
}

.contact-icon {
  width: 24px; /* Standard size for icons */
  height: 24px; /* Matching height to width for square icons */
  margin-inline-end: 10px; /* Space between icon and text */
  color: #13122c;
}

.list-group-item {
  border: none; /* Removes borders from list items if desired */
  padding: 10px 0; /* Top and bottom padding for list items, no horizontal padding */
}

.list-group-item a {
  text-decoration: none;
  color: #13122c;
}
