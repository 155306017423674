section .home-text {
  color: #ddd;
  max-width: 1020px;
  font-size: 1.1em;
  padding-top: 35px !important;
}
.home-bold {
  font-weight: bold;
  font-size: 1.4em;
  line-height: 1.5em;
}

.home-text a,
.home-text a:hover {
  color: var(--primary-color);
}
