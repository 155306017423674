/*------------------------------------------
      CUSTOM LANDING - VIDEO DIV
 ------------------------------------------*/
.landing-video-div {
  position: relative;
  background-color: #13122c;
  color: white;
  height: 100vh;
  max-height: 500px;
}

.video-embed {
  position: absolute;
  top: 300px;
  left: 50%;
  border-radius: 15px;
  overflow: hidden;
  transform: translate(-50%, -50%);
  z-index: 11;
}

.video-embed iframe {
  width: 350px;
  height: 197px;
}

/* Break Points for Video */
@media (orientation: landscape) and (max-width: 768px) {
  .video-embed {
    top: 210px;
  }
}
@media only screen and (min-width: 768px) {
  .video-embed {
    top: 50%;
  }

  .video-embed iframe {
    width: 550px;
    height: 350px;
  }
}
@media only screen and (min-width: 1000px) {
  .landing-video-div {
    max-height: none;
  }
  .video-embed iframe {
    width: 800px;
    height: 500px;
  }
}
@media only screen and (min-width: 1200px) {
  .video-embed iframe {
    width: 1000px;
    height: 550px;
  }
}
