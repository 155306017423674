/*------------------------------------------
      CUSTOM LANDING - CONTINUE JOURNEY DIV
 ------------------------------------------*/
.journey-div {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  max-height: 850px;
  min-height: 400px;
}
.theWay .journey-div {
  background-image: url("/public/img/footer-theWay.webp");
}
.theLight .journey-div {
  background-image: url("/public/img/footer-theLight.webp");
}
.theVine .journey-div {
  background-image: url("/public/img/footer-theVine.webp");
}
.yellow-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 207, 63, 0.5);
  z-index: 1;
}

.journey-content {
  position: relative;
  z-index: 2;
  text-align: center;
  padding-top: 50px;
}

.titles-box {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0 auto 50px;
}

.title {
  text-align: start;
  font-size: 48px;
  font-family: "Mandala", sans-serif;
  color: #000;
  margin-bottom: 0px;
  padding-bottom: 0px;
  line-height: 50px;
}

.subtitle {
  font-size: 1.2em;
  font-family: "Mandala", sans-serif;
  color: #000;
  text-align: start;
  padding-top: 0px;
}

.circle-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.circle-link {
  font-family: "Mandala", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #13122c;
  margin: 0 20px;
}

.circle-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 3px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5em;
  transform-origin: center;
  transition: all 0.3s ease;
}

.circle-image:hover {
  transform: scale(1.5);
}

.image-title {
  font-size: 1em;
}

@media only screen and (min-width: 768px) {
  .journey-div {
    max-height: 450px;
  }
  .titles-box {
    width: 40%;
  }
  .circle-images {
    flex-direction: row;
    gap: 7%;
  }
}
