/*------------------------------------------
      CUSTOM LANDING - TRIANGLES
 ------------------------------------------*/

/* Custom Triangles */
.custom-triangle {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
  background-color: var(--primary-color);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Left Triangles */
.custom-triangle-left {
  clip-path: none;
}

/* Left Triangle Text */
.custom-triangle-left-text {
  margin: 0;
  padding: 0 15px;
  font-size: 1.4em; /* Adjust the font size as needed */
  word-break: break-word;
  color: #13122c;
  font-family: "Mandala", sans-serif;
}

/* Right Triangle Text */
.custom-triangle-right-text {
  margin: 0;
  padding: 0 15px;
  font-size: 1.4em; /* Adjust the font size as needed */
  word-break: break-word;
  color: #13122c;
  font-family: "Mandala", sans-serif;
}

/* Right Triangle */
.custom-triangle-right {
  right: 0;
  height: 350px;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}

/* Break Points for Triangles */
@media only screen and (min-width: 768px) {
  .custom-triangle {
    width: 350px;
    height: 350px;
  }

  .custom-triangle-left {
    left: 0;
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }

  .custom-triangle-left-text {
    margin-top: -140px;
    margin-left: 40px; /* Adjust as needed */
    margin-right: 60%; /* Adjust so text doesn't overflow the triangle */
    font-size: 1.2em; /* Adjust the font size as needed */
    padding: 0 5px;
  }

  .custom-triangle-right-text {
    margin-top: -140px;
    margin-right: 40px; /* Adjust as needed */
    margin-left: 60%; /* Adjust so text doesn't overflow the triangle */
    font-size: 1.2em; /* Adjust the font size as needed */
    padding: 0 5px;
  }
}
