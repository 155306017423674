/*------------------------------------------
      CUSTOM LANDING - READ MORE DIV
 ------------------------------------------*/
.read-more-div {
  position: relative;
  background-color: white;
  color: black;
  height: 100vh;
  min-height: 500px;
  max-height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-with-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
  padding: 30px;
}

.content-photo {
  max-width: 80%;
  margin-inline-end: 30px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.content-text {
  max-width: 90%;
}

.content-text .btn {
  background-color: var(--primary-color);
}

.icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.icon-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
  text-decoration: none;
  font-family: "Mandala", sans-serif;
  color: #13122c;
}

.icon {
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
}

@media (orientation: landscape) and (max-width: 768px) {
  .read-more-div {
    min-height: 550px;
  }
}

@media only screen and (min-width: 768px) {
  .read-more-div {
    max-height: 600px;
  }
  .content-with-photo {
    flex-direction: row;
  }

  .content-photo {
    max-width: 20%;
  }

  .content-text {
    max-width: 50%;
  }
  .icons-container {
    gap: 30%;
  }
  .icon-link {
    min-width: min-content;
  }
  .icon-link span {
    white-space: nowrap;
  }
  .icon {
    width: 84px;
    height: 84px;
  }
}
@media only screen and (min-width: 1000px) {
  .read-more-div {
    max-height: none;
  }
}
